@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

#accountVerificationForm {
  #resend-container {
    height: 24px;
    padding-top: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  .button-group {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}
