@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.success-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .svgicon {
    width: 75px;
    height: 75px;
    fill: limegreen;
    background: #e7f7e2;
    border-radius:38px;
  }
}
