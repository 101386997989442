@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

div.fakeHidden {
  overflow: hidden;
  height: 0px;
  margin: 0px;
}

#recoveryOptions {
  height: 24px;
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;

  #forgotUsernameButton {
    padding-right: 3px;
    padding-left: 3px;
    font-size: 12px;
  }

  #passwordHintLink{
    padding-right: 5px;
    padding-left: 4px;
    vertical-align: inherit;
    font-size: 12px;
  }

  .popover-item{
    display: block;
    top: 166px;
    left: 825px;
  }

  .popover-text{
    font-size: 13px;
  }
}

#forgotPasswordBtn {
  font-size: 12px;
  margin-top: 4px;
}

.username-text{
  font-size: 13px;
}

#user-form-navigation-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.input-wrapper.form-group {
  margin-bottom: 0;
}

#return-link {
  max-width: 100%;
}
