@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.icon-stack {
  display: flex;
  position: relative;
  align-self: center;
}

.stacked-icon {
  position: absolute;
  left: 0;
  width: 100%;
  align-self: center;
}