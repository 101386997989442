@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.icon-circle {
  height: $icon-size-l;
  width: $icon-size-l;

  &.svgicon--active {
    fill: $blue200;
  }
}

.circled-icon {
  align-self: center;

  &.svgicon--active {
    fill: $blue400;
  }
}