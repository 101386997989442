@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

#enrollFactorHelpText {
  height: 24px;
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
}

.button-enroll-factor-submit {
  padding-top: 16px;
}

.email-factor-input {
  #factor-input-inputWrapper {
    margin-bottom: 0;
  }
}

#enroll-factor-form-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
