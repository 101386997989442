@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

#otp-authentication {
  #otpCode-inputWrapper {
    margin-bottom: 0;
  }

  #resend-container {
    height: 24px;
    padding-top: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  #otpValidationButtons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}

