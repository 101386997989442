@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.success-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .message {
    font-weight: bold;
  }
}
