@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

@import "variables";

.accountVerificationHeader {
  margin-top: $margin-large-vertical;
  margin-bottom: $margin-large-vertical;
}

.accountVerificationSmsDisclaimerLabel {
  font-size: smaller;
  color: $gray-dark;
}

.accountVerificationHelpText{
  font-size: smaller;
}

.accountVerificationCardText {
  text-align: left;
}

.panel-default.factor-card {
  border-radius: $border-radius-base;
}

.icon-stack {
  margin-right: $spacing-m;
}

.icons-svg {
  color: $blue400;
}

.button-verify {
  align-self: center;
}
