@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.launch-controller{
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}

.launcher-selection-form{
  width: 100%;
}

.logoButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  height: 60px;
}

.logoButton img {
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

.logoButton:hover, .logoButton:active, .logoButton:focus{
  background-color: inherit !important;
}

@media screen and (max-width: 767px){
  .launch-controller{
    top:20px
  }
}

@media screen and (max-height: 545px){
  .launch-controller{
    top:20px
  }
}
