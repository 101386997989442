@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.button-row{
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 8px;
}

.button-item[id^='solButton']{
  white-space: normal;
}

.popover-text{
  font-size: 13px;
}

.popover-item{
  display: block;
  top: 166px;
  left: 825px;
}

#return-link-disambiguation {
  max-width: 100%;
}

#disambiguation-form-text {
  overflow-wrap: break-word;
}
