@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

div.fakeHidden {
  overflow: hidden;
  height: 0px;
  margin: 0px;
}

#revealPassword {
  float: right;
}

div.passwordChangeSuccess {
  text-align: center;
}

div.passwordChangeSuccess svg {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 75px;
  height: 75px;
}

.help-block {
  white-space: pre-line;
}

#password-reset-form-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
