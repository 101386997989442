@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.link-button {
  padding: 0;
  border: 0;
  background: none;
  color: #2b6bdd;
}

.link-button:hover {
  color: #6090e5;
}

.link-button[disabled] {
  color: #cacfd9;
  cursor: not-allowed;
}
