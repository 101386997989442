@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.userUnlinkedErrorPage {
  text-align: left;

  .redirect-user {
    margin-top: 10px;
    text-align: center;

    .attach-solution-button {
      margin-bottom: 5px;
      overflow: auto;
    }
  }
}
