@import '../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

@import "variables";

.cx-alert {
  margin-top: 20px;
  margin-bottom: 20px;
}

.app-content {
    //Note if we update this make sure to update page-footer to match
    margin-left: auto;
    margin-right: auto;
    margin-top: $headerHeight + 15;
    padding-top: 5px;
    max-width: $appMaxWidth;
    min-width: $appMinWidth;
    padding-left: $appPadding;
    padding-right: $appPadding;

}

