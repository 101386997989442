@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

@import "variables";

.factorEnrollmentShowDisclaimerLabel {
  font-size: smaller;
  color: $gray-dark;
}

.factorEnrollmentHideDisclaimerLabel {
  font-size: smaller;
  color: transparent;
}

.factorEnrolmentHelpText {
  margin-top: $margin-base-vertical;
  margin-bottom: floor($margin-base-vertical * 3);
}

.factorEnrolmentOptionHeader {
  margin-top: $margin-large-vertical;
  margin-bottom: $margin-large-vertical;
}

.factorEnrollCardText {
  text-align: left;
}

.panel-default.factor-card {
  border-radius: $border-radius-base;
}

.icon-stack {
  margin-right: $spacing-m;
}

.button-enroll {
  align-self: center;
}
