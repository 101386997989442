@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.card-template {
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-template .panel-title {
  width: 100%;
}

.panel-body, .panel-body > div {
  width: 100%;
}

.logo {
  display: block;
  margin-left: -16px;
  margin-right: -16px;

  justify-content: center;
  align-items: center;
}

.logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  max-width: calc(100% - 32px);
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  background-color:lightgray;
  height: 1px;
}

@media screen and (max-width: 767px) {
  .card-template {

  }
  .card-template .panel,
  .card-template .panel-default > .panel-heading{
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0px;
  }

  .card-template .btn-link {
    background-color: transparent;
    border-color: transparent;
  }
}
