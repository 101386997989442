@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

@import "variables";

.broadcast-message-container {
  padding-top: 10px;
}

.broadcast-message {
  width: 100%;
  margin-top: $headerHeight;
  margin-bottom: -$headerHeight; // nasty little hack knowing that the application is shifted by this much
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  background-size: auto 30px;
  z-index: 10000;
  padding-bottom: 10px;
}
.broadcast-message > .btn {
  margin-left: 16px;
}

.text-white {
  color: white;
}
