@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

@import "variables";

.page-footer{
  background: #fafafa;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 15px;
  //z-index: -1; // the select box on solution launcher was falling below the footer.

  font-size: 10px;
  line-height: 1.5;
}

.copyright{
  color: rgb(137,141,148);
  text-align: center;

  span {
    display: inline-block;
  }
}

.terms-of-use-link{
  text-align: left;
}

.privacy-link{
  text-align: right;
}

@media (max-width: 767px), (max-height: 670px){
  .page-footer{
    position: relative;
    margin-top: 30px;
    padding: 5px $appPadding;
    border-top: 1px solid #d3d3d3;

    //Note if we update this make sure to update app-content to match
    margin-left: auto;
    margin-right: auto;

    max-width: $appMaxWidth;
    min-width: $appMinWidth;

  }

  .terms-of-use-link, .privacy-link{
    text-align: left;
  }
}
